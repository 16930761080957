import { Pipe, PipeTransform, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@Pipe({ name: "idFormat" })
export class ChapterIdPipe implements PipeTransform {
  transform(value: string, args?: any[]): string {
    return "i" + value.replace(/\./g, "_");
  }
}

@NgModule({
  declarations: [ChapterIdPipe],
  imports: [CommonModule],
  exports: [ChapterIdPipe]
})
export class ChapterIdPipeModule {}
