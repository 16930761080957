import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbsComponent } from "./breadcrumbs.component";
import { RouterModule } from "@angular/router";
import { ProgressBarModule } from "../progress-bar/progress-bar.module";

@NgModule({
  declarations: [BreadcrumbsComponent],
  exports: [BreadcrumbsComponent],
  imports: [CommonModule, RouterModule, ProgressBarModule]
})
export class BreadcrumbsModule {}
